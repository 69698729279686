import { SmileyTypes } from '@/components/svg-components/Smiley.vue';
import { User } from '@/models/User';
import { Project, ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import { EventBusKey } from '@vueuse/core';

export const RECOMMENDATIONS_EVENT_REFRESH = 'refresh';
export const RECOMMENDATIONS_EVENT_REFRESH_FEEDBACKS = 'refresh_feedbacks';

export type RecommendationAction = 'add' | 'remove' | ''

export enum RecommendationApiWarning {
  LIMIT_FOR_USER_REACHED = 'E400_RECOMMENDATION_RULE_LIMIT_FROM_USER_REACHED',
  TIME_LIMIT_FOR_USER_2_USER_REACHED = 'E400_RECOMMENDATION_RULE_LIMIT_FOR_USER_FROM_USER_WITHIN_TIMELIMIT_REACHED',
  PROJECTPREFERENCES_DO_NOT_MATCH = 'E400_RECOMMENDATION_RULE_PROJECT_PREFERENCES_NOT_MATCHING',
  RECOMMENDATION_ALREADY_EXISTS = 'E400_RECOMMENDATION_RULE_RECOMMENDATION_ALREADY_EXISTS',
  RECOMMENDING_IS_CREATOR = 'E400_RECOMMENDATION_RULE_RECOMMENDING_USER_IS_PROJECT_CREATOR',
  USER_BLOCKED = 'E400_RECOMMENDATION_RULE_RECOMMENDATION_FOR_USER_FROM_USER_BLOCKED',
  RECOMMENDED_IS_CREATOR = 'E400_RECOMMENDATION_RULE_RECOMMENDED_USER_IS_PROJECT_CREATOR'
}

// Because why should it be simple...
export const RecommendationErrorCodeMapping:Record<string, RecommendationApiWarning> = {
  'BC-REC-401': RecommendationApiWarning.RECOMMENDED_IS_CREATOR,
  'BC-REC-402': RecommendationApiWarning.LIMIT_FOR_USER_REACHED,
  'BC-REC-404': RecommendationApiWarning.TIME_LIMIT_FOR_USER_2_USER_REACHED,
  'BC-REC-406': RecommendationApiWarning.PROJECTPREFERENCES_DO_NOT_MATCH,
  'BC-REC-403': RecommendationApiWarning.RECOMMENDATION_ALREADY_EXISTS,
  'BC-REC-407': RecommendationApiWarning.RECOMMENDATION_ALREADY_EXISTS,
  'BC-REC-400': RecommendationApiWarning.RECOMMENDING_IS_CREATOR,
  'BC-REC-405': RecommendationApiWarning.USER_BLOCKED
};

export interface ExternalProject {
  created: string;
  id: string;
  creator: User;
  title: string;
  startDate: string;
}

export interface ExternalProjectList {
  externalProjectList: ExternalProject[];
}

export enum PropertyState {
  PREFERRED='PREFERRED',
  MANDATORY='MANDATORY'
}

export interface ExternalRecommendationVerifyPreferencesListEntry {
  propertyKey: string;
  propertyState: PropertyState;
}

export interface ResponseRecommendationVerify {
  project: Project;
  warning?: string;
  projectPreferencesPropertyList: {
    verifyResultList: ExternalRecommendationVerifyPreferencesListEntry[];
  };
}

export enum RecommendationFeedbackType {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
}

export type RecommendationPreference = 'LOCATION' | 'REMOTE' | 'MICRO_PROJECTS' | 'COMPANY_SIZE' | 'INDUSTRIES' | 'AVAILABILITY';

export interface RecommendationFeedbackRequest {
  userFeedback: RecommendationFeedbackType;
  userFeedbackMessage?: string;
  notFittingMySkills?: boolean;
  notFittingMyPreferences?: boolean;
  userIdToBlock?: string;
  notFittingPreferencesList?: RecommendationPreference[];
}

export enum RecommendationStatus {
  Open = 'OPEN',
  InMyProjects = 'IN_MY_PROJECTS',
  AcceptedByProject = 'ACCEPTED_BY_PROJECT',
  Rejected = 'REJECTED'
}

export enum RecommendationInitiatedBy {
  Subscription = 'SUBSCRIPTION',
  Recommendation = 'RECOMMENDATION',
  Creation = 'CREATION'
}

export enum Mystatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

export interface ExternalRecommendation {
  externalId: string;
  created: string;
  recommendingUser: User;
  recommendedUser: User;
  status: RecommendationStatus;
  recommendingMessage?: string;
  recommendedUserFeedbackResponseMessage?: string;
  recommendedUserFeedback?: RecommendationFeedbackType;
  recommendedUserFeedbackNotFittingPreferences?: boolean;
  recommendedUserFeedbackNotFittingPreferencesList?: RecommendationPreference[];
  recommendedUserFeedbackNotFittingSkills?: boolean;
  project: ProjectListProject;
  recommendingMessageFlagged: boolean;
  recommendedUserFeedbackResponseMessageFlagged: boolean;
}
export interface MyRecommendationsResponse {
  recommendations: ExternalRecommendation[];
}

export interface ExternalUserProject {
  externalId: string;
  createdTimestamp: string;
  initiatedBy: RecommendationInitiatedBy;
  project: ProjectListProject;
  recommendation: ExternalRecommendation;
  status: Mystatus;
}

export interface ResponseMyProjects {
  externalUserProjectList: ExternalUserProject[];
}

export type RecommendationsForMeResponse = MyRecommendationsResponse

export const PAGE_SIZE_RECOMMENDATIONS = 5;
export const PAGE_SIZE_MY_RECOMMENDATIONS = 10;

export const feedBackType2SmileyType:Record<RecommendationFeedbackType, SmileyTypes> = {
  [RecommendationFeedbackType.Negative]: SmileyTypes.Bad,
  [RecommendationFeedbackType.Neutral]: SmileyTypes.Neutral,
  [RecommendationFeedbackType.Positive]: SmileyTypes.Good
};

type RecommendationEvent = string;

// eslint-disable-next-line import/prefer-default-export
export const recommendationsEventKey: EventBusKey<RecommendationEvent> = Symbol('recommendationsEventKey');
