import { HtmlTarget } from '@/models/HtmlTarget';
import { User } from '@/models/User';
import { ref, unref, watch } from 'vue';
import { createGlobalState, MaybeRef, useTimeoutFn } from '@vueuse/core';

export const DELETED_USER_FULLNAME = 'ehemaliger benutzer';
type QuickInfoSections = 'profile' | 'contact-history' | 'badges-legend';
type QuickInfoOpenWith = QuickInfoSections | 'show-more';

const useQuickInfo = createGlobalState(() => {
  const quickInfoUser = ref<User | null>(null);
  const quickInfoX = ref<number>(0);
  const quickInfoY = ref<number>(0);
  const hasCoordinatesAlready = ref(false);
  const target = ref<HtmlTarget>('_self');
  const displayedSection = ref<QuickInfoSections>('profile');
  const showMore = ref(false);

  const resetQuickInfo = () => {
    showMore.value = false;
    displayedSection.value = 'profile';
    hasCoordinatesAlready.value = false;
  };

  const setQuickInfoUser = (user:User | null) => {
    quickInfoUser.value = user;
    if (!user) resetQuickInfo();
  };

  function closeQuickInfo() {
    setQuickInfoUser(null);
  }

  const setQuickInfoCoords = (x: number, y: number) => {
    quickInfoX.value = x;
    quickInfoY.value = y;
    hasCoordinatesAlready.value = true;
  };

  const setQuickInfo = (user: User | null, x: number, y: number, toTarget: HtmlTarget = '_self') => {
    if ((user?.fullname ?? '').trim().toLowerCase() === DELETED_USER_FULLNAME) {
      return;
    }
    target.value = toTarget;
    setQuickInfoUser(user);
    if (!hasCoordinatesAlready.value) setQuickInfoCoords(x, y);
  };

  const showQuickInfo = (el:MaybeRef<HTMLElement | SVGElement | null>, user:MaybeRef<User | null>, openWith:QuickInfoOpenWith = 'profile') => {
    const parentElement = unref(el)?.parentElement;
    if (parentElement) {
      const { left, top } = parentElement.getBoundingClientRect();
      if (openWith === 'show-more') {
        displayedSection.value = 'profile';
        useTimeoutFn(() => {
          showMore.value = true;
        }, 100);
      } else if (openWith) {
        displayedSection.value = openWith;
      }
      setQuickInfo(unref(user), left, top + 50);
    }
  };

  return ({
    quickInfoUser,
    quickInfoX,
    quickInfoY,
    setQuickInfo,
    setQuickInfoCoords,
    setQuickInfoUser,
    showQuickInfo,
    target,
    closeQuickInfo,
    showMore,
    displayedSection,
    resetQuickInfo
  });
});

export default useQuickInfo;
