import Vue from 'vue';

export enum InfoBoxType {
  INFOBOX_PUB_VISITORS = 'pub_visitors',
  INFOBOX_INFO = 'info',
  INFOBOX_STANDARD = 'standard',
  INFOBOX_WARNING = 'warning',
  INFOBOX_RECOMMENDATION = 'recommendation',
  INFOBOX_APPLY = 'apply',
  INFOBOX_RECOMMENDATION_DISABLED = 'recommendation_not-available',
  INFOBOX_PROJECT_ACTION_BOX = 'project-action-box',
  INFOBOX_CONTRACT_ACTIONS = 'contract_actions'
}

export interface InfoBoxState {
  isActive: boolean;
  isOpened: boolean;
  text: string | null;
  headline: string | null;
  icon: Vue | null;
  type: InfoBoxType;
  mobileComponent?: Vue | null;
}
