import setNavigationGuards from '@/router/navigationGuards';
import Vue from 'vue';
import './plugins/axios';
// import FocusNextOnEnter from '@/directives/FocusNextOnEnter';
import BCXClickOutside from '@/directives/ClickOutside';
import { abilitiesPlugin } from '@casl/vue';
import * as Sentry from '@sentry/vue';
import VueObserveVisibility from 'vue-observe-visibility';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import shadow from 'vue-shadow-dom';
import { logDebug } from '@/utils/logger';
import { isStageEnvironment } from '@/utils/isEnvironment';
import App from './App.vue';
import i18n, { setDocumentLanguage } from './i18n';
import router from './router';
import store from './store';
import ability from './ability';
import '@/components/globals/globals';
import '@/models/Window';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@/assets/styles/global.scss';

declare global {
  interface Window {
    setLogLevel: CallableFunction;
    getLogLevel: CallableFunction;
  }
}

dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);

Vue.use(shadow);

Vue.config.productionTip = false;

// Vue.directive('focus-next-on-enter', FocusNextOnEnter);
Vue.directive('bcx-click-outside', BCXClickOutside);

Vue.config.errorHandler = (err, vm, info) => {
  console.error('err, vm, info', { err, vm, info });
};

Vue.use(VueObserveVisibility);

Vue.use(abilitiesPlugin, ability);
(window as any).ability = ability;
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    logDebug('tab aktiviert');
  } else {
    logDebug('tab deaktiviert');
  }
});

if (isStageEnvironment) {
  Sentry.init({
    Vue,
    dsn: 'https://107058658bb54d8cb4d3491fa652e2c1@o1064151.ingest.sentry.io/6054833',
    environment: 'production',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ['stage-api.bytecookie.net/api', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

setNavigationGuards();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

setDocumentLanguage(i18n.locale);
