
import {
  computed, defineComponent, PropType, ref, Ref,
  toRefs, watch
} from 'vue';
import BCXButtonDropdown from '@/components/molecules/forms/BCXButtonDropdown.vue';
import BCXButtonDropdownResetButton from '@/components/molecules/forms/BCXButtonDropdownResetButton.vue';
import BCXMultiselectSection, { BCXExtendedDropdownItem, BCXExtendedDropdownItemValue } from '@/components/molecules/forms/BCXMultiselectSection.vue';
import { useVModel } from '@vueuse/core';
import hasArrayEqualContents from '@/utils/hasArrayEqualContents';

export default defineComponent({
  name: 'BCXMultiselectFilter',
  components: {
    BCXMultiselectSection,
    BCXButtonDropdownResetButton,
    BCXButtonDropdown
  },
  props: {
    items: {
      type: Array as () => BCXExtendedDropdownItem[],
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [Array, String] as PropType<BCXExtendedDropdownItemValue[] | BCXExtendedDropdownItemValue | null>,
      default: null
    },
    defaultValue: {
      type: [Array, String] as PropType<BCXExtendedDropdownItemValue[] | BCXExtendedDropdownItemValue | null>,
      default: null
    },
    visibleValues: {
      type: Array as PropType<BCXExtendedDropdownItemValue[] | null>,
      default: null,
    },
    required: Boolean,
    maxPanelWidth: {
      type: Number,
      default: 250
    },
    customSizeSelection: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String as PropType<'filter' | 'dropdown'>,
      default: 'filter'
    },
    showResetButton: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { mode, defaultValue, visibleValues } = toRefs(props);
    const showDropdown: Ref<boolean> = ref(false);
    const isMultiple = computed(() => Array.isArray(props.value));
    const selectedValue = useVModel(props);

    const visibleDefaults = computed(() => {
      const visible = visibleValues.value;
      const defaults = defaultValue.value;
      if (!Array.isArray(defaults) || !Array.isArray(visible)) return defaults;
      if (visible) {
        return defaults.filter((item) => visible.includes(item));
      }
      return defaults;
    });

    const visibleSelected = computed(() => {
      const visible = visibleValues.value;
      const selected = selectedValue.value as BCXExtendedDropdownItemValue[];
      if (!Array.isArray(selected) || !Array.isArray(visible)) return selected;
      if (visible) {
        return selected.filter((item) => visible.includes(item));
      }
      return selected;
    });

    const visibleItems = computed(() => {
      const visible = visibleValues.value;
      if (visible) {
        return props.items.filter((item) => visible.includes(item.value));
      }
      return props.items;
    });

    const selectionCount = computed(() => {
      if (isMultiple.value) {
        return (visibleSelected.value as BCXExtendedDropdownItemValue[]).length;
      }
      return selectedValue.value ? 1 : 0;
    });

    const setShowDropdown = (value: boolean) => {
      showDropdown.value = value;
    };

    const hasCustomSelection = computed(() => {
      if (Array.isArray(visibleDefaults.value) && Array.isArray(visibleSelected.value)) return !hasArrayEqualContents(visibleDefaults.value, visibleSelected.value);
      return defaultValue.value !== props.value;
    });

    const resetFilter = () => {
      selectedValue.value = defaultValue.value;
    };

    const titleWithCount = computed(() => (hasCustomSelection.value ? `${props.title} (${selectionCount.value})` : props.title));

    const isModeFilter = computed(() => mode.value === 'filter');
    const isModeDropdown = computed(() => mode.value === 'dropdown');

    const checkboxType = computed(() => {
      if (isModeDropdown.value) {
        return 'outlined';
      }
      return 'dark';
    });

    // // react to changing default value
    // watch(defaultValue, (defaults, oldDefaults) => {
    //   const selected = selectedValue.value as BCXExtendedDropdownItemValue[];
    //   if (Array.isArray(defaults)
    //     && Array.isArray(oldDefaults)
    //     && Array.isArray(selected)) {
    //     const newItems = defaults.filter((item) => !oldDefaults.includes(item) && !selected.includes(item));
    //     const removedItems = oldDefaults.filter((item) => !defaults.includes(item) && selected.includes(item));
    //
    //     selectedValue.value = [...newItems, ...selected.filter((item) => !removedItems.includes(item))];
    //   }
    // });

    return {
      selectedValue,
      setShowDropdown,
      showDropdown,
      resetFilter,
      hasCustomSelection,
      titleWithCount,
      isModeDropdown,
      isModeFilter,
      checkboxType,
      visibleItems,
      visibleSelected
    };
  }
});
