import { BaseUserWithRole } from '@/models/User';

export enum InvoiceStatus { // @todo: move to real model file when defined
  Open = 'open',
  Paid = 'paid',
  Overdue = 'overdue',
  New = 'new',
  Sent = 'sent',
  Draft = 'draft',
}

export interface Invoice { // @todo: move to real model file when defined
  id: string;
  user: BaseUserWithRole;
  reference: string;
  costCenter: string;
  due: string;
  status: InvoiceStatus;
}

export const fakeInvoices: Invoice[] = [
  {
    id: '1',
    user: {
      firstname: 'Sophie',
      lastname: 'Müller',
      userId: 'fallback3'
    },
    reference: 'ERP System Implementation',
    costCenter: 'Finance',
    due: '2025-03-03',
    status: InvoiceStatus.Paid,
  },
  {
    id: '2',
    user: {
      firstname: 'Maximilian',
      lastname: 'Schneider',
      userId: 'fallback4'
    },
    reference: 'Cybersecurity Enhancement',
    costCenter: 'Sales',
    due: '2025-03-08',
    status: InvoiceStatus.Open,
  },
  {
    id: '3',
    user: {
      firstname: 'Jonas',
      lastname: 'Hoffmann',
      userId: 'fallback23'
    },
    reference: 'AI Chatbot Deployment',
    costCenter: 'Finance',
    due: '2025-03-13',
    status: InvoiceStatus.Paid,
  },
  {
    id: '4',
    user: {
      firstname: 'Tim',
      lastname: 'Becker',
      userId: 'fallback25'
    },
    reference: 'E-commerce Platform Redesign',
    costCenter: 'Operations',
    due: '2025-03-18',
    status: InvoiceStatus.New,
  },
  {
    id: '5',
    user: {
      firstname: 'David',
      lastname: 'Zimmermann',
      userId: 'fallback13'
    },
    reference: 'Big Data Integration',
    costCenter: 'Finance',
    due: '2025-03-20',
    status: InvoiceStatus.Paid,
  },
  {
    id: '6',
    user: {
      firstname: 'Alexander',
      lastname: 'Schmitt',
      userId: 'fallback14'
    },
    reference: 'DevOps Automation Rollout',
    costCenter: 'Sales',
    due: '2025-03-22',
    status: InvoiceStatus.Open,
  },
  {
    id: '7',
    user: {
      firstname: 'Finn',
      lastname: 'Koch',
      userId: 'fallback15'
    },
    reference: 'Blockchain Pilot Program',
    costCenter: 'Operations',
    due: '2025-03-24',
    status: InvoiceStatus.New,
  },
];
