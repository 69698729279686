import { BcxFormField, BcxFormValidation } from '@/models/BcxForm';
import useI18n from '@/mixins/useI18n';

export const isZip = (value:string) => (/\d{4,5}/).test(value);
export const isEmail = (value:string) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(value);

export const useFormValidation = () => {
  const { t } = useI18n();

  const validateField = (value: string, field: BcxFormField, data:Record<string, string>) => {
    let errorMessage = '';
    if (field.required && !value?.trim()) {
      errorMessage = `${t('common.bcxFormValidations.required', { field: field.label })}`;
    } else {
      field?.validations?.some((validation) => {
        if (typeof validation === 'string') {
          switch (validation) {
            case BcxFormValidation.EMAIL:
              if (!isEmail(value)) errorMessage = `${t('common.bcxFormValidations.email', { field: field.label })}`;
              break;
            case BcxFormValidation.ZIP:
              if (!isZip(value)) errorMessage = `${t('common.bcxFormValidations.zip', { field: field.label })}`;
              break;
          }
        } else if (typeof validation === 'function') {
          errorMessage = validation(value, data) ?? '';
        }
        return !!errorMessage;
      });
    }
    return errorMessage;
  };

  return { validateField };
};
