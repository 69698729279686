const useCurrency = () => {
  const numberFormat = Intl.NumberFormat('de-DE', {
    currency: 'EUR',
    minimumFractionDigits: 2, // Mindestens 2 Nachkommastellen
    maximumFractionDigits: 2, // Höchstens 2 Nachkommastellen
  });

  const format = (value: string|number) => numberFormat.format(+value);

  return { format };
};

export default useCurrency;
