import {
  BackendError, BackendErrors, PossibleErrorResponse, TranslatedErrorCode
} from '@/models/BackendError';
import { AxiosResponse } from 'axios';
import { logDebug } from '@/utils/logger';
import usePageState from '@/state/pageState';

const translateValidationCode = (key:string) => {
  switch (key) {
    case 'invite_code.not_valid': return TranslatedErrorCode.INVALID_INVITE_CODE;
    case 'user.email.not_unique': return TranslatedErrorCode.EMAIL_NOT_UNIQUE;
    case 'user.email.not_valid': return TranslatedErrorCode.EMAIL_NOT_VALID;
    case 'user.email.not_verified': return TranslatedErrorCode.EMAIL_NOT_VERIFIED;
    case 'user.firstname.not_consistent': return TranslatedErrorCode.FIRSTNAME_NOT_CONSISTENT;
    case 'user.firstname.min_length': return TranslatedErrorCode.FIRSTNAME_MIN_LENGTH;
    case 'user.firstname.max_length': return TranslatedErrorCode.FIRSTNAME_MAX_LENGTH;
    case 'user.firstname.size': return TranslatedErrorCode.FIRSTNAME_SIZE;
    case 'user.firstname.empty': return TranslatedErrorCode.FIRSTNAME_EMPTY;
    case 'user.id.not_valid': return TranslatedErrorCode.USER_ID_NOT_VALID;
    case 'user.registration.mailcode.not_valid': return TranslatedErrorCode.EMAIL_CODE_INVALID;
    case 'user.lastname.not_consistent': return TranslatedErrorCode.LASTNAME_NOT_CONSISTENT;
    case 'user.lastname.min_length': return TranslatedErrorCode.LASTNAME_MIN_LENGTH;
    case 'user.lastname.max_length': return TranslatedErrorCode.LASTNAME_MAX_LENGTH;
    case 'user.lastname.size': return TranslatedErrorCode.LASTNAME_SIZE;
    case 'user.lastname.empty': return TranslatedErrorCode.LASTNAME_EMPTY;
    case 'registration.legal.not_confirmed': return TranslatedErrorCode.REGISTRATION_LEGAL_NOT_CONFIRMED;
    case 'user.mobilephone.not_valid_or_inuse': return TranslatedErrorCode.MOBILE_NUMBER_IN_USE;
    case 'user.mobilephone.already_exists': return TranslatedErrorCode.MOBILE_NUMBER_IN_USE;
    case 'user.mobilephone.not_valid': return TranslatedErrorCode.MOBILE_NUMBER_NOT_VALID;
    case 'user.mobilephone.not_verified': return TranslatedErrorCode.MOBILE_NUMBER_NOT_VERIFIED;
    case 'user.password.empty': return TranslatedErrorCode.PASSWORD_EMPTY;
    case 'user.password.min_length': return TranslatedErrorCode.PASSWORD_TOO_SHORT;
    case 'user.password.not_valid': return TranslatedErrorCode.PASSWORD_NOT_VALID;

    default:
      return null;
  }
};

const translateErrorCode = (rawCode:string) => {
  switch (rawCode) {
    case 'BC-X-001': return TranslatedErrorCode.UNSPECIFIC_ERROR;
    case 'BC-UIC-005':
    case 'BC-UIC-004': return TranslatedErrorCode.INVITE_CODE_ALREADY_USED;
    case 'BC-USR-004': return TranslatedErrorCode.REGISTRATION_REG_ID_AND_PROC_ID_NOT_FOUND;
    case 'BC-USR-009': return TranslatedErrorCode.REGISTRATION_MISSING_PARAMETER;
    case 'BC-USR-012': return TranslatedErrorCode.ACCOUNT_SUSPENDED;
    case 'BC-USR-021': return TranslatedErrorCode.EMAIL_CODE_INVALID;
    case 'BC-USR-028': return TranslatedErrorCode.REGISTRATION_INVALID_USERSKILL_ID;
    case 'BC-USR-029': return TranslatedErrorCode.REGISTRATION_USERSKILL_NOT_FOUND;
    case 'BC-USR-030': return TranslatedErrorCode.REGISTRATION_MOBILE_NUMBER_MISSING;
    case 'BC-USR-034':
    case 'BC-USR-0345': return TranslatedErrorCode.MOBILE_VERIFICATION_CODE_INVALID;
    case 'BC-USR-036': return TranslatedErrorCode.REGISTRATION_SKILLS_POKID_INVALID;
    case 'BC-USR-037': return TranslatedErrorCode.REGISTRATION_INVALID_USERSKILLID_OR_USERPOKID;
    case 'BC-USR-038': return TranslatedErrorCode.REGISTRATION_USERPOK_NOT_FOUND;
    case 'BC-USR-039': return TranslatedErrorCode.MOBILE_NUMBER_IN_USE;
    case 'BC-USR-047': return TranslatedErrorCode.LOGIN_NOT_FOUND;
    case 'BC-USR-090': return TranslatedErrorCode.REGISTRATION_WRONG_STATUS;
    case 'BC-USR-099': return TranslatedErrorCode.SETTINGS_MAIL_EXISTS;
    case 'BC-USR-101':
    case 'BC-USR-092': return TranslatedErrorCode.PASSWORD_ALREADY_GIVEN;
    case 'BC-SEC-005': return TranslatedErrorCode.PLEASE_LOGIN;
    case 'BC-SEC-022': return TranslatedErrorCode.TOO_MANY_LOGINS;
    case 'BC-USET-003': return TranslatedErrorCode.PASSWORD_DOES_NOT_MATCH;
    case 'BC-CMY-002': return TranslatedErrorCode.COMPANY_NAME_ALREADY_EXIST;
    case 'BC-PRO-001': return TranslatedErrorCode.OFFER_NOT_FOUND;
    case 'BC-CTR-005': return TranslatedErrorCode.CONTRACT_ONLY_PARTNER_ALLOWED;
    case 'BC-X-500': return TranslatedErrorCode.INVALID_INPUT_DATA;
    case 'BC-CTR-002': return TranslatedErrorCode.CONTRACT_PROJECT_REFERENCE_CANNOT_BE_OVERWRITTEN;
    case 'BC-CTR-003': return TranslatedErrorCode.CONTRACT_STATUS_CHANGE_FORBIDDEN;
    case 'BC-GEO-002': return TranslatedErrorCode.INVALID_COUNTRY_ISO_CODE;
    case 'BC-GEO-006': return TranslatedErrorCode.POSTCODE_NOT_FOUND;
    case 'BC-CTR-004': return TranslatedErrorCode.CONTRACT_DELETE_FORBIDDEN_EXISTING_DOCUMENTS;
    case 'BC-CD_001': return TranslatedErrorCode.CONTRACT_DOCUMENT_ALREADY_SIGNED;
    case 'BC-CD-002': return TranslatedErrorCode.CONTRACT_DOC_ONLY_PARTNER_ALLOWED;
    case 'BC-CD-003': return TranslatedErrorCode.CONTRACT_DOC_ONLY_CREATOR_ALLOWED;
    case 'BC-CD-004': return TranslatedErrorCode.CONTRACT_DOC_ALREADY_VIEWED;
    default:
  }
  if (rawCode) return `${TranslatedErrorCode.UNKNOWN_ERROR}`; // Fallback for other errors -> needs rework
  return null;
};

const backendErrorTranslator = (response: AxiosResponse):BackendErrors | null => {
  const data = response.data as PossibleErrorResponse;

  const errorCode = translateErrorCode(data?.error?.code ?? '');
  const validationErrors:BackendError[] = [];

  data?.validation?.forEach((validationField) => {
    const inputField = validationField.field;
    const keys = new Map<string, unknown>();
    validationField.messages.forEach((validationMessage) => {
      keys.set(validationMessage.key, validationMessage.params);
    });
    logDebug('Keys found in validation error:', keys.entries());
    [...keys.entries()].forEach(([key, params]) => {
      const code = translateValidationCode(key);
      logDebug('Code for key', key, ' is ', code);
      if (code) {
        validationErrors.push({
          code,
          inputField,
          params,
        });
      }
    });
  });

  logDebug('Errorcode:', errorCode, 'validationErrors:', validationErrors);

  return {
    code: data?.error?.code ?? '',
    errorCode,
    validationErrors
  };
};

class TranslatedBackendErrors {
  public backendErrors

  constructor(response:AxiosResponse) {
    this.backendErrors = backendErrorTranslator(response);
  }

  setError(code:TranslatedErrorCode) {
    if (this.backendErrors) {
      this.backendErrors.errorCode = code;
    }
  }

  hasError(code:TranslatedErrorCode) {
    return code === this.backendErrors?.errorCode
      || this.validationErrors.includes(code);
  }

  hasErrors() {
    return !!(this.backendErrors?.errorCode || this.backendErrors?.validationErrors?.length);
  }

  get validationErrors():TranslatedErrorCode[] {
    return this.backendErrors?.validationErrors?.map?.((backendError) => backendError.code) ?? [];
  }

  get allErrors():TranslatedErrorCode[] | string[] {
    const errorCode = this.backendErrors?.errorCode;
    return [
      ...errorCode ? [errorCode] : [],
      ...this.validationErrors
    ];
  }

  outputGlobalErrorMessage(component?:string) {
    const { globalErrorMessage } = usePageState();
    globalErrorMessage.value = {
      i18Key: `validation.backend_errors.${(this.backendErrors?.errorCode ?? TranslatedErrorCode.UNKNOWN_ERROR)}`,
      code: (this.backendErrors?.code ?? TranslatedErrorCode.UNKNOWN_ERROR),
      withoutReload: true,
      component: component ?? 'TranslatedBackendErrors'
    };
  }
}

export default TranslatedBackendErrors;
