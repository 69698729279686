const hasObjectDiffRecursive = (obj1: any, obj2: any, except: string[]): boolean => {
  if (obj1 === obj2) {
    return false;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return true;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return true;
  }

  if (Object.keys(obj1).some((key) => !except.includes(key) && hasObjectDiffRecursive(obj1[key], obj2[key], except))) {
    return true;
  }

  return false;
};

export default hasObjectDiffRecursive;
