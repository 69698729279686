
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXPoints from '@/components/molecules/BCXPoints.vue';
import BCXUserBadges from '@/components/molecules/BCXUserBadges.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { AvatarSize, AvatarSizes } from '@/models/Avatar';
import { HtmlTarget } from '@/models/HtmlTarget';
import { User } from '@/models/User';
import { BannerUserState } from '@/models/UserState';
import useQuickInfo from '@/state/quickInfo';
import store from '@/store';
import { SingleUserFromSearch } from '@/views/user-search/models/UserSearch';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { templateRef } from '@vueuse/core';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'BCXUserBanner',
  components: {
    BCXPoints,
    BCXAvatar,
    BCXUserBadges
  },
  props: {
    insideQuickinfo: {
      type: Boolean
    },
    insideMessenger: {
      type: Boolean
    },
    size: {
      type: String as PropType<AvatarSizes>,
      default: 'L'
    },
    showOnlineIndicator: {
      type: Boolean,
      default: false
    },
    showCookies: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ''
    },
    user: {
      type: Object as () => SingleUserFromSearch,
      default: () => ({})
    },
    target: {
      type: String as PropType<HtmlTarget>,
      default: '_self'
    },
    type: {
      type: String as PropType<'normal' | 'user-search'>,
      default: 'normal'
    },
    hideBadges: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      insideQuickinfo, size, userId, insideMessenger
    } = toRefs(props);

    const viewUser = computed<BannerUserState & User>(() => store.getters['userStates/getUserInfo']((userId.value ?? 'user')));
    const hasUserId = computed(() => (viewUser.value.userId?.length ?? 0) > 0);
    const { displayName } = useUser(viewUser);

    const avatarSize = computed<AvatarSize>(() => AvatarSize[size.value]);

    // Quickinfo
    const el = templateRef<HTMLElement>('el');
    const { showQuickInfo, displayedSection } = useQuickInfo();
    const { isMobileWidth } = useResponsiveness();

    const onClick = () => {
      if (insideQuickinfo.value) return;
      showQuickInfo(el, viewUser.value);
    };
    const badgesTheme = computed(() => (isMobileWidth.value && (insideQuickinfo.value || insideMessenger.value) ? 'dark' : 'bright'));

    const badgeClicked = () => {
      if (el.value?.parentElement) {
        displayedSection.value = 'badges-legend';
        if (!insideQuickinfo.value) {
          showQuickInfo(el, viewUser.value, 'badges-legend');
        }
      }
    };

    return {
      onClick,
      avatarSize,
      viewUser,
      hasUserId,
      badgeClicked,
      badgesTheme,
      displayName
    };
  }
});
