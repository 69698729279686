

import Vue, { PropType } from 'vue';

const orientations = {
  up: 0,
  down: 180,
  right: 90,
  left: -90,
};

export default Vue.extend({
  props: {
    orientation: { type: String as PropType<keyof typeof orientations>, default: 'up' },
    width: { type: Number, default: 24 },
    height: { type: Number, default: 24 },
    strokeWidth: { type: Number, default: 2 },
  },

  computed: {
    rotation():number {
      return (orientations as any)[this.orientation];
    },
    path():string {
      const w = (this.width * 0.5) - this.strokeWidth;
      const h = (this.height * 0.5) - this.strokeWidth;

      const yOffset = this.height * 0.73 - this.strokeWidth * 0.5;
      return `M${this.strokeWidth * 0.5},${yOffset} l${w},${-h} l${w},${h}`;
    },
  },
});
