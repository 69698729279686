import { createGlobalState, useMediaQuery } from '@vueuse/core';

/**
 * useResponsiveness
 * uses a composable & global state to manage all important responsive breakpoints
 * for the bytecookie page.
 *
 * @author nc
 */
const useResponsiveness = createGlobalState(() => {
  const isAvailabilityADot = useMediaQuery('(max-width:500px)');
  const isSmartphoneWidth = useMediaQuery('(max-width:480px)');
  const isMobileWidth = useMediaQuery('(max-width:766px)');
  const isMini = useMediaQuery('(min-width:767px) and (max-width:1439px)');
  const isFlat = useMediaQuery('(max-height:640px) and (orientation:landscape)');
  const isTooFlatForMessenger = useMediaQuery('(max-height:600px) and (orientation:landscape)');
  const isTabletWidth = useMediaQuery('(min-width:767px) and (max-width:1255px)');
  const isDesktopWidth = useMediaQuery('(min-width:1256px)');
  const isLandingPageMobileWidth = useMediaQuery('(max-width:799px)');
  const isLandingPageTabletWidth = useMediaQuery('(min-width:800px) and (max-width:1019px)');
  const isAvailabilityTabletWidth = useMediaQuery('(max-width:1150px)');

  return {
    isSmartphoneWidth,
    isMobileWidth,
    isMini,
    isFlat,
    isTabletWidth,
    isDesktopWidth,
    isMobileLayout: isMobileWidth,
    isSidebarVisible: isDesktopWidth,
    isLandingPageMobileWidth,
    isLandingPageTabletWidth,
    isTooFlatForMessenger,
    isAvailabilityADot,
    isAvailabilityTabletWidth
  };
});

export default useResponsiveness;
