
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import { truncate } from 'lodash';
import useQuickInfo from '@/state/quickInfo';
import useUser from '@/mixins/useUser';
import { Group } from '@/views/forum/models/Forum';

export default defineComponent({
  props: {
    forumItem: {
      type: Object as PropType<Group>,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters('page', ['isMobileLayout']),
    ...mapGetters('userStates', ['getUserInfo']),
    forumLink(): RawLocation {
      return { name: 'threads', params: { forumId: this.forumItem.forumId, groupId: this.forumItem.groupId } };
    },
    latestThreadTitle(): string {
      if (this.forumItem.forumThreadGroupMetaData.latestAddedThreadId) {
        return truncate(this.forumItem.forumThreadGroupMetaData.latestAddedThreadTitle, { length: (this.isMobileLayout ? 58 : 53) });
      }
      return '';
    },
    latestThreadTimestamp(): string {
      if (this.forumItem.forumThreadGroupMetaData.latestAddedThreadId) {
        return this.formatDate(this.forumItem.forumThreadGroupMetaData.latestAddedThreadTs);
      }
      return '';
    },
    latestThreadCreator(): string {
      const latestCreator = this.forumItem.forumThreadGroupMetaData.latestAddedThreadCreator;
      const { displayName } = useUser(latestCreator);
      return displayName.value;
    },
    linkToLatestThread(): RawLocation {
      return {
        name: 'thread',
        params: {
          forumId: this.forumItem.forumId,
          groupId: this.forumItem.groupId,
          threadId: this.forumItem.forumThreadGroupMetaData.latestAddedThreadId
        }
      };
    },
    numberOfThreadsLabel(): string {
      return this.$tc('forum.labels.threads', this.forumItem.forumThreadGroupMetaData.numberOfThreads) as string;
    }
  },
  methods: {
    formatDate(timestamp: string): string {
      const { getFormattedTimeByAge } = useFormattedDateTime();
      return getFormattedTimeByAge(timestamp);
    },
    showUserQuickInfoCreator(ev: Event) {
      const { showQuickInfo } = useQuickInfo();
      showQuickInfo(ev.target as HTMLElement, this.forumItem.forumThreadGroupMetaData.latestAddedThreadCreator);
    },
  },
});
