import { BaseCompany, BaseUserWithRole } from '@/models/User';
import { Project } from '@/views/project-forum/models/ProjectForum';
import { ProjectOfferWithProjectReference } from './MyProjects';

export enum ContractStatus {
  Open = 'OPEN',
  Signed = 'SIGNED',
  Canceled = 'CANCELLED',
  Ended = 'ENDED',
  Running = 'RUNNING'
}

export interface BaseContract {
  id: string;
  partner: BaseUserWithRole;
  project: Project;
  status: ContractStatus;
  lastChanged: string; // timestamp
  read: boolean;
}

export interface Address {
  street?: string;
  supplement?: string;
  houseNumber?: string;
  postalCode?: string;
  city?: string;
  countryIsoCode?: string;
}

export interface ContractPartner {
  user?: BaseUserWithRole;
  company?: BaseCompany;
  address?: Address;
  contractSignDate: string;
  costcenter: string;
  department: string;
  contactPerson: string;
  contractNotes: string;
}

export interface Contract {
  contractId: string;
  title: string;
  status: ContractStatus;
  client: ContractPartner;
  contractor: ContractPartner;
  bcReferenceId: string;
  creator: string; // user-id
  created: string; // date string
  lastUpdated: string; // date string
  reference: string;
}

export interface NewContractRequest {
  client?: boolean;
  contractPartnerId: string;
  message: string;
}

interface File {
  attachment: string;
  fileType: string;
  filename: string;
}
export interface ContractDocumentFile {
  id: string;
  versionNo: number;
  changes: string;
  created: string;
  creator: BaseUserWithRole;
  externalFile?: File;
}
export interface ContractDocument {
  id: string;
  title: string;
  remarks: string;
  signedByClient: boolean;
  signedByContractor: boolean;
  lastChanged: string;
  files: ContractDocumentFile[];
  creator: BaseUserWithRole;
}
export interface ContractDetail extends Contract {
  bcReferenceId: string;
  reference: string;
  projectOffer: ProjectOfferWithProjectReference;
  documents: ContractDocument[];
  deletable: boolean;
  readyForConclusion: boolean;
}

export interface ContractUpdateAddress {
  street?: string;
  houseNumber?: string;
  supplement?: string;
  countryIsoCode?: string;
  postalCode?: string;
  city?: string;
}

export interface ContractUpdateNotes {
  costCenter?: string;
  contactPerson?: string;
  department?: string;
  notes?: string;
}

export interface CreateContractDocumentRequest {
  title?: string;
  data?: string;
  remarks?: string;
}
