import { render, staticRenderFns } from "./MyProjectsContractsActions.vue?vue&type=template&id=3128ff1b&scoped=true"
import script from "./MyProjectsContractsActions.vue?vue&type=script&setup=true&lang=ts"
export * from "./MyProjectsContractsActions.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MyProjectsContractsActions.vue?vue&type=style&index=0&id=3128ff1b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3128ff1b",
  null
  
)

export default component.exports