import MyProjects from '@/views/my-projects/pages/MyProjects.vue';
import { RouteConfig } from 'vue-router';
import MyProjectOffers from '@/views/my-projects/pages/MyProjectOffers.vue';
import MyProjectsCreatedAndWatched from '@/views/my-projects/pages/MyProjectsCreatedAndWatched.vue';
import MyProjectsContracts from '@/views/my-projects/pages/MyProjectsContracts.vue';
import MyProjectsContractsActions from '@/views/my-projects/components/contracts/MyProjectsContractsActions.vue';
import MyProjectContract from '@/views/my-projects/pages/MyProjectContract.vue';
import MyProjectsInvoices from '@/views/my-projects/pages/MyProjectsInvoices.vue';
import InvoicesActions from '@/views/my-projects/components/invoices/action-box/InvoicesActions.vue';
import MyProjectsInvoice from '@/views/my-projects/pages/MyProjectsInvoice.vue';

const routes: Array<RouteConfig> = [
  {
    name: 'myProjects',
    path: '/my-projects/',
    component: MyProjects,
    meta: {
      breadcrumbNames: ['projects'],
      subComponent: MyProjectsCreatedAndWatched,
      layout: 'full',
      auth: true,
      keepAlive: true,
    },
  },
  {
    name: 'myProjectsInvoices',
    path: '/my-projects/invoices',
    component: MyProjects,
    meta: {
      sidebarComponent: InvoicesActions,
      breadcrumbNames: ['invoices'],
      subComponent: MyProjectsInvoices,
      layout: 'full',
      auth: true,
      keepAlive: true,
    },
  },
  {
    name: 'myProjectsInvoice',
    path: '/my-projects/invoice/:invoiceId',
    component: MyProjects,
    meta: {
      sidebarComponent: InvoicesActions,
      breadcrumbNames: ['invoices', 'invoice'],
      subComponent: MyProjectsInvoice,
      layout: 'full',
      auth: true,
      keepAlive: true,
    }
  },
  {
    name: 'myProjectsInvoiceNew',
    path: '/my-projects/invoice/new/:contractId',
    component: MyProjects,
    meta: {
      sidebarComponent: InvoicesActions,
      breadcrumbNames: ['invoices', 'invoice'],
      subComponent: MyProjectsInvoice,
      layout: 'full',
      auth: true,
      keepAlive: true,
    }
  },
  {
    name: 'myProjectsContracts',
    path: '/my-projects/contracts',
    component: MyProjects,
    meta: {
      sidebarComponent: MyProjectsContractsActions,
      breadcrumbNames: ['contracts'],
      subComponent: MyProjectsContracts,
      layout: 'full',
      auth: true,
      keepAlive: true,
    },
  },
  {
    name: 'myProjectsContract',
    path: '/my-projects/contract/:contractId',
    component: MyProjects,
    meta: {
      sidebarComponent: MyProjectsContractsActions,
      breadcrumbNames: ['contracts', 'contract'],
      subComponent: MyProjectContract,
      layout: 'full',
      auth: true,
      keepAlive: true,
    },
  },
  {
    name: 'myProjectsContracts',
    path: '/my-projects/contracts',
    component: MyProjects,
    meta: {
      sidebarComponent: MyProjectsContractsActions,
      breadcrumbNames: ['contracts'],
      subComponent: MyProjectsContracts,
      layout: 'full',
      auth: true,
      keepAlive: true,
    },
  },
  {
    name: 'offersForMyProject',
    path: '/my-projects/offers/:projectId',
    component: MyProjects,
    meta: {
      breadcrumbNames: ['offers'],
      subComponent: MyProjectOffers,
      dontScrollBetween: true,
      keepAlive: true,
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'myProjectOffers',
    path: '/my-projects/offers',
    component: MyProjects,
    meta: {
      breadcrumbNames: ['offers'],
      subComponent: MyProjectOffers,
      dontScrollBetween: true,
      keepAlive: true,
      layout: 'full',
      auth: true
    }
  }
];
export default routes;
