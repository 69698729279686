import { AxiosResponse } from 'axios';

/**
 * This should help to move backend errors to the frontend. Simple.
 */
export enum TranslatedErrorCode {
  // Validations
  INVALID_INVITE_CODE = 'invite_code_not_valid',
  EMAIL_NOT_UNIQUE = 'email_not_unique',
  EMAIL_NOT_VALID = 'email_not_valid',
  EMAIL_NOT_VERIFIED = 'email_not_verified',
  MOBILE_NUMBER_IN_USE = 'mobile_number_in_use',
  FIRSTNAME_NOT_CONSISTENT = 'firstname_not_consistent',
  FIRSTNAME_MIN_LENGTH = 'firstname_min_length',
  FIRSTNAME_MAX_LENGTH = 'firstname_max_length',
  FIRSTNAME_SIZE = 'firstname_size',
  FIRSTNAME_EMPTY = 'firstname_empty',
  LASTNAME_NOT_CONSISTENT = 'lastname_not_consistent',
  LASTNAME_MIN_LENGTH = 'lastname_min_length',
  LASTNAME_MAX_LENGTH = 'lastname_max_length',
  LASTNAME_SIZE = 'lastname_size',
  LASTNAME_EMPTY = 'lastname_empty',
  REGISTRATION_LEGAL_NOT_CONFIRMED = 'registration_legal_not_confirmed',
  MOBILE_NUMBER_NOT_VALID = 'mobile_number_not_valid',
  MOBILE_NUMBER_NOT_VERIFIED = 'mobile_number_not_verified',
  PASSWORD_EMPTY = 'password_empty',
  PASSWORD_TOO_SHORT = 'password_too_short',
  PASSWORD_NOT_VALID = 'password_not_valid',
  USER_ID_NOT_VALID = 'user_id_not_valid',
  // Errors
  UNSPECIFIC_ERROR = 'unspecific_error',
  INVITE_CODE_ALREADY_USED = 'invite_code_already_used',
  REGISTRATION_WRONG_STATUS = 'wrong_status',
  SETTINGS_MAIL_EXISTS = 'mail_exists',
  MOBILE_VERIFICATION_CODE_INVALID = 'mobile_verification_code_invalid',
  EMAIL_CODE_INVALID = 'email_code_not_valid',
  PASSWORD_DOES_NOT_MATCH = 'password_does_not_match',
  TOO_MANY_LOGINS = 'too_many_logins',
  UNKNOWN_ERROR = 'unknown_error',
  LOGIN_NOT_FOUND = 'login_not_found',
  PLEASE_LOGIN = 'please_login',
  ACCOUNT_SUSPENDED = 'account_suspended',
  PASSWORD_ALREADY_GIVEN = 'password_already_given',
  REGISTRATION_INVALID_USERSKILL_ID = 'registration_invalid_userskill_id',
  REGISTRATION_REG_ID_AND_PROC_ID_NOT_FOUND = 'registration_reg_id_and_proc_id_not_found',
  REGISTRATION_MISSING_PARAMETER = 'registration_missing_parameter',
  REGISTRATION_USERSKILL_NOT_FOUND = 'registration_userskill_not_found',
  REGISTRATION_MOBILE_NUMBER_MISSING = 'registration_mobile_number_missing',
  REGISTRATION_SKILLS_POKID_INVALID = 'registration_skills_pokid_invalid',
  REGISTRATION_INVALID_USERSKILLID_OR_USERPOKID = 'registration_invalid_userskillid_or_userpokid',
  REGISTRATION_USERPOK_NOT_FOUND = 'registration_userpok_not_found',
  COMPANY_NAME_ALREADY_EXIST = 'registration_company_name_already_exist',
  OFFER_NOT_FOUND = 'offer_not_found',
  CONTRACT_ONLY_PARTNER_ALLOWED = 'contract_only_partner_allowed',
  INVALID_INPUT_DATA = 'invalid_input_data',
  CONTRACT_PROJECT_REFERENCE_CANNOT_BE_OVERWRITTEN = 'contract_project_reference_cannot_be_overwritten',
  CONTRACT_STATUS_CHANGE_FORBIDDEN = 'contract_status_change_forbidden',
  POSTCODE_NOT_FOUND = 'postcode_not_found',
  INVALID_COUNTRY_ISO_CODE = 'invalid_country_iso_code',
  CONTRACT_DELETE_FORBIDDEN_EXISTING_DOCUMENTS = 'contract_delete_forbidden_existing_documents',
  CONTRACT_DOC_ONLY_CREATOR_ALLOWED = 'contract_doc_only_creator_allowed',
  CONTRACT_DOC_ONLY_PARTNER_ALLOWED = 'contract_doc_only_partner_allowed',
  CONTRACT_DOCUMENT_ALREADY_SIGNED = 'contract_document_already_signed',
  CONTRACT_DOC_ALREADY_VIEWED = 'contract_doc_already_viewed',
}

export interface ValidationFieldErrorMessage {
  key: string;
  params?: unknown[];
}

export interface ValidationFieldError {
  field: string;
  messages: ValidationFieldErrorMessage[];
}

export interface PossibleErrorResponse extends AxiosResponse {
  requestId: string;
  validation?: ValidationFieldError[];
  error?: Record<string, string>;
}

export interface BackendError {
  code: TranslatedErrorCode;
  params?: unknown;
  inputField?: string | null;
}

export interface BackendErrors {
  errorCode?: TranslatedErrorCode | string | null;
  validationErrors?: BackendError[] | null;
  code?: string | null;
}
