var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bcx-form-values",style:({
    '--form-h-gap': _vm.horizontalGap,
    '--v-gap': _vm.verticalGap
  })},[_c('div',{staticClass:"c-form-field-values"},_vm._l((_vm.fields),function(field){return _c('div',{key:field.name,staticClass:"c-form-field-value",style:({
        width: field.width,
        marginLeft: field.justify === 'end' ? 'auto' : ''
      })},[_c('h4',{staticClass:"c-headline"},[_vm._v(" "+_vm._s(field.label)+" ")]),_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.data[field.name])+" ")]},{"value":_vm.data[field.name],"fieldName":field.name})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }