import { asyncComputed, createGlobalState } from '@vueuse/core';
import { SelectItem } from '@/models/SelectItem';
import { getPlatformCountries } from '@/services/Geo/Api';

const useAddressTools = createGlobalState(() => {
  const countries = asyncComputed<SelectItem[]>(async () => (await getPlatformCountries())
    .map(({
      countryName,
      isoCode,
    }) => ({
      value: isoCode,
      text: countryName,
    })),
  [], { lazy: true });

  return { countries };
});

export default useAddressTools;
