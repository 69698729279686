
import { User } from '@/models/User';
import useQuickInfo from '@/state/quickInfo';
import { defineComponent, PropType, toRefs } from 'vue';
import { templateRef } from '@vueuse/core';

export default defineComponent({
  name: 'BCXLinkedUser',
  props: {
    user: {
      type: Object as PropType<User>,
      default: null
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  setup(props) {
    const { user } = toRefs(props);
    const el = templateRef<HTMLElement>('el');
    const { showQuickInfo } = useQuickInfo();

    const showProfile = () => {
      showQuickInfo(el, user.value, 'contact-history');
    };

    return {
      showProfile
    };
  }
});
