
import usePaginationHelper from '@/mixins/usePaginationHelper';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { syncRef, useOffsetPagination, useVModel } from '@vueuse/core';
import BCXPaginationSwitcher from '@/components/molecules/BCXPaginationSwitcher.vue';

export default defineComponent({
  name: 'BCXPagination',
  components: { BCXPaginationSwitcher },
  props: {
    currentPage: {
      type: Number, default: 1,
    },
    pageSize: {
      type: Number, default: 10,
    },
    total: {
      type: Number, default: 0
    },
    isAlwaysShown: {
      type: Boolean
    },
    align: {
      type: String as PropType<'center' | 'left'>,
      default: 'center'
    },
    alwaysShowArrows: Boolean
  },
  setup(props) {
    const { pageSize, total } = toRefs(props);
    const currentPageVM = useVModel(props, 'currentPage');

    const {
      isFirstPage, isLastPage, next: opNext, prev: opPrev, currentPage, currentPageSize, pageCount
    } = useOffsetPagination({
      page: props.currentPage,
      pageSize,
      total,
    });

    const prev = () => {
      if (isFirstPage.value) currentPageVM.value = pageCount.value;
      else opPrev();
    };

    const next = () => {
      if (isLastPage.value) currentPageVM.value = 1;
      else opNext();
    };

    syncRef(currentPage, currentPageVM);

    const { firstItemOnPage: from } = usePaginationHelper(pageSize, currentPage);

    const to = computed(() => Math.min(from.value + currentPageSize.value - 1, total.value));

    return {
      isFirstPage,
      isLastPage,
      next,
      prev,
      from,
      to,
      currentPageSize
    };
  }
});
