import AvailableUsersPanel from '@/views/virtual-pub/components/available-users/AvailableUsersPanel.vue';
import ChatRoom from '@/views/virtual-pub/pages/ChatRoom.vue';
import VirtualPub from '@/views/virtual-pub/pages/VirtualPub.vue';
import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/virtual-pub',
    name: 'virtual-pub',
    component: VirtualPub,
    meta: {
      auth: true,
      layout: 'full',
      sidebarComponent: AvailableUsersPanel,
      mainClasses: ['virtual-pub-full-page'],
    }
  },
  {
    path: '/chat-room',
    name: 'chat-room',
    component: ChatRoom,
    meta: {
      auth: true,
      layout: 'plain',
      mainClasses: ['bcx-secondary']
    }
  }
];

export default routes;
