import {
  CHAT_TYPE_DIRECT, CHAT_TYPE_FORUM, CHAT_TYPE_PROJECT, ChatFilter
} from '@/models/Messenger';
import { NotificationCountType } from '@/models/Notification';
import useMessengerState from '@/state/messenger/messengerState';
import useRouterIngoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import useResponsiveness from '@/mixins/useResponsiveness';
import useNotificationState from '@/state/notificationState';

const useNotificationActions = () => {
  const { resetInterval, showPanel } = useNotificationState();
  const {
    isOpened, isExpanded, groupFilters
  } = useMessengerState();
  const { pushRoute } = useRouterIngoreDuplicate();

  const gotoMessenger = async (type:string) => {
    const { isMobileWidth } = useResponsiveness();
    // console.log('SetGroup called with', type);
    Object.assign(groupFilters, {
      all: false,
      [CHAT_TYPE_DIRECT]: type === CHAT_TYPE_DIRECT,
      [CHAT_TYPE_PROJECT]: type === CHAT_TYPE_PROJECT,
      [CHAT_TYPE_FORUM]: type === CHAT_TYPE_FORUM
    } as ChatFilter);
    isOpened.value = true;
    isExpanded.value = !isMobileWidth.value;
    resetInterval();
    showPanel.value = false;
  };

  const gotoRoute = async (name: string, query?: Record<string, any>) => {
    await pushRoute({
      name,
      ...query
    });
  };

  const gotoItem = async (itemName: NotificationCountType) => {
    switch (itemName) {
      case NotificationCountType.NewProjectChanges:
      case NotificationCountType.NewProjects:
      case NotificationCountType.NewProjectComments:
        await gotoRoute('subscriptions', { filter: 'PROJECTS' });
        break;
      case NotificationCountType.NewRecommendations:
      case NotificationCountType.NewRecommendationFeedbacks:
        await gotoRoute('recommendations');
        break;
      case NotificationCountType.NewProjectOffers:
      case NotificationCountType.ChangedProjectOffers:
        await gotoRoute('myProjectOffers');
        break;

      case NotificationCountType.DirectMessage:
        await gotoMessenger(CHAT_TYPE_DIRECT);
        break;
      case NotificationCountType.ForumMessage:
        await gotoMessenger(CHAT_TYPE_FORUM);
        break;
      case NotificationCountType.ProjectMessage:
        await gotoMessenger(CHAT_TYPE_PROJECT);
        break;

      case NotificationCountType.NewTalkComments:
      case NotificationCountType.NewTalks:
        await gotoRoute('subscriptions', { filter: 'TALKS' });
        break;
      case NotificationCountType.NewContracts:
      case NotificationCountType.ChangedContracts:
        await gotoRoute('myProjectsContracts');
        break;
      default:
    }
  };

  const getRoute = (itemName: NotificationCountType) => {
    switch (itemName) {
      case NotificationCountType.NewProjectChanges:
      case NotificationCountType.NewProjects:
      case NotificationCountType.NewProjectComments:
        return { name: 'subscriptions', query: { filter: 'PROJECTS' } };
        break;
      case NotificationCountType.NewRecommendations:
      case NotificationCountType.NewRecommendationFeedbacks:
        return { name: 'recommendations' };
        break;
      case NotificationCountType.NewProjectOffers:
      case NotificationCountType.ChangedProjectOffers:
        return { name: 'myProjectOffers' };
        break;
      case NotificationCountType.NewTalkComments:
      case NotificationCountType.NewTalks:
        return { name: 'subscriptions', query: { filter: 'TALKS' } };
        break;
    }
    return null;
  };

  return {
    gotoItem,
    getRoute
  };
};

export default useNotificationActions;
