var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.field)?_c('div',{staticClass:"bc-form-field",class:_vm.field.sonderlocke ? `c-sonderlocke--${_vm.field.sonderlocke}` : null,style:(_setup.style)},[_c('h4',{staticClass:"c-headline",class:{'c-error-headline': _vm.hasError}},[_vm._v(" "+_vm._s(_vm.field.type !== 'checkbox' ? _vm.field.label : ' ')+" ")]),_c('div',{staticClass:"c-form-input",class:[`c-form-input--${_vm.field.type}`, {
      'c-form-input--flex': _vm.field.suffix
    }]},[(_vm.field.type === 'select')?_c(_setup.BCXSearchSelect,{staticClass:"bcx-full-width",style:({
        width: _vm.field.fieldWidth
      }),attrs:{"placeholder":_vm.field.placeholder ?? '',"list":_vm.field.options,"mode":_vm.field.mode ?? 'select',"disabled":_vm.field.disabled,"invalid":_vm.hasError},on:{"input":_setup.onDelayedBlur,"update:search":function($event){return _setup.emit('input', $event)}},model:{value:(_setup.vmValue),callback:function ($$v) {_setup.vmValue=$$v},expression:"vmValue"}}):(_vm.field.type==='textarea')?_c(_setup.BCXTextArea,{style:({
        width: _vm.field.fieldWidth
      }),attrs:{"placeholder":_vm.field.placeholder ?? '',"height":_vm.field?.textareaOptions?.height,"enlarged-height":_vm.field?.textareaOptions?.enlargedHeight,"maxlength":_vm.field?.textareaOptions?.maxLength,"show-enlarge-icon":!!_vm.field?.textareaOptions?.enlargedHeight,"is-enlarged":_setup.isTextareaEnlarged,"disabled":_vm.field.disabled,"invalid":_vm.hasError},on:{"update:isEnlarged":function($event){_setup.isTextareaEnlarged=$event},"update:is-enlarged":function($event){_setup.isTextareaEnlarged=$event},"blur":function($event){return _setup.emit('blur')}},model:{value:(_setup.vmValue),callback:function ($$v) {_setup.vmValue=$$v},expression:"vmValue"}}):(_vm.field.type==='checkbox')?_c(_setup.BCXCheckbox,{attrs:{"checked":!!_setup.vmValue,"disabled":_vm.field.disabled},on:{"input":function($event){return _vm.$emit('input', !_setup.vmValue)},"blur":function($event){return _setup.emit('blur')}}},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]):(_vm.field.type==='date')?_c(_setup.BCXDatepickerField,{style:({
        width: _vm.field.fieldWidth
      }),attrs:{"earliest":_vm.field?.dateOptions?.earliest,"latest":_vm.field?.dateOptions?.latest,"is-secondary":_vm.field?.dateOptions?.secondary},on:{"blur":function($event){return _setup.emit('blur')}},model:{value:(_setup.vmDateValue),callback:function ($$v) {_setup.vmDateValue=$$v},expression:"vmDateValue"}}):(_vm.field.type==='currency')?_c(_setup.BCXCurrencyInput,{attrs:{"currency-symbol":_vm.field.currencyOptions ? _vm.field.currencyOptions.symbol : '?',"decimals":_vm.field.currencyOptions ? _vm.field.currencyOptions.decimals : 2},on:{"blur":function($event){return _setup.emit('blur')}},model:{value:(_setup.vmValue),callback:function ($$v) {_setup.vmValue=$$v},expression:"vmValue"}}):(_vm.field.type==='plaintext')?_c('div',{staticClass:"c-plaintext"},[_vm._v(" "+_vm._s(_setup.vmValue)+" ")]):_c('div',[((_vm.field.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.vmValue),expression:"vmValue"}],staticClass:"bcx-input",class:{'invalid': _vm.hasError},style:({
          width: _vm.field.fieldWidth ?? '100%'
        }),attrs:{"placeholder":_vm.field.placeholder ?? '',"disabled":_vm.field.disabled,"maxlength":_vm.field.textOptions ? _vm.field.textOptions.maxLength : undefined,"min":_vm.field.textOptions ? _vm.field.textOptions.min : undefined,"max":_vm.field.textOptions ? _vm.field.textOptions.max : undefined,"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.vmValue)?_vm._i(_setup.vmValue,null)>-1:(_setup.vmValue)},on:{"blur":function($event){return _setup.emit('blur')},"change":function($event){var $$a=_setup.vmValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.vmValue=$$a.concat([$$v]))}else{$$i>-1&&(_setup.vmValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.vmValue=$$c}}}}):((_vm.field.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.vmValue),expression:"vmValue"}],staticClass:"bcx-input",class:{'invalid': _vm.hasError},style:({
          width: _vm.field.fieldWidth ?? '100%'
        }),attrs:{"placeholder":_vm.field.placeholder ?? '',"disabled":_vm.field.disabled,"maxlength":_vm.field.textOptions ? _vm.field.textOptions.maxLength : undefined,"min":_vm.field.textOptions ? _vm.field.textOptions.min : undefined,"max":_vm.field.textOptions ? _vm.field.textOptions.max : undefined,"type":"radio"},domProps:{"checked":_vm._q(_setup.vmValue,null)},on:{"blur":function($event){return _setup.emit('blur')},"change":function($event){_setup.vmValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.vmValue),expression:"vmValue"}],staticClass:"bcx-input",class:{'invalid': _vm.hasError},style:({
          width: _vm.field.fieldWidth ?? '100%'
        }),attrs:{"placeholder":_vm.field.placeholder ?? '',"disabled":_vm.field.disabled,"maxlength":_vm.field.textOptions ? _vm.field.textOptions.maxLength : undefined,"min":_vm.field.textOptions ? _vm.field.textOptions.min : undefined,"max":_vm.field.textOptions ? _vm.field.textOptions.max : undefined,"type":_vm.field.type},domProps:{"value":(_setup.vmValue)},on:{"blur":function($event){return _setup.emit('blur')},"input":function($event){if($event.target.composing)return;_setup.vmValue=$event.target.value}}}),(_vm.field.textOptions && _vm.field.textOptions.maxLength)?_c('div',{staticClass:"c-hint c-chars-left"},[_vm._v(" "+_vm._s(_vm.$t('common.charactersLeft', { x: _setup.charsLeft }))+" ")]):_vm._e()]),(_vm.field.suffix)?_c('span',{staticClass:"c-suffix"},[_vm._v(_vm._s(_vm.field.suffix))]):_vm._e()],1),(_vm.field.hint)?_c('div',{staticClass:"c-hint"},[_vm._v(" "+_vm._s(_vm.field.hint)+" ")]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }