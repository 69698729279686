import { render, staticRenderFns } from "./NewAvailability.vue?vue&type=template&id=09a96aa6&scoped=true"
import script from "./NewAvailability.vue?vue&type=script&lang=ts&setup=true"
export * from "./NewAvailability.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./NewAvailability.vue?vue&type=style&index=0&id=09a96aa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a96aa6",
  null
  
)

export default component.exports