import BCXIcon from '@/components/molecules/BCXIcon.vue';
<template>
  <button
    class=""
    :class="`blog__button ${color} ${inverted ? 'inverted' : ''}`"
    @click="$emit('click')"
  >
    <span
      v-if="loading"
      class="loader"
    >
      <BCXIcon icon="DotsDots" />
    </span>
    <span>{{ label }}</span>
  </button>
</template>

<script>
import BCXIcon from '@/components/molecules/BCXIcon.vue';

export default {
  components: {
    BCXIcon
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    inverted: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.blog__button {
  padding: 6px 28px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px;
  position: relative;
  color: var(--button-text);
  background: var(--button-bg);
  border-color: var(--button-bg);
  font-weight: 500;
  overflow: hidden;

  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--button-text);
    background: var(--button-bg);
    .c-icon-base {
      animation: pulse 2s infinite;
      opacity: 0.6;
      font-size: 20px;
      color: inherit;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }

  &.btn-orange {
    --button-text: #ffffff;
    --button-bg: #d07d01;
  }

  &.btn-red {
    --button-text: #ffffff;
    --button-bg: #962525;
  }
  &.btn-alert {
    --button-text: #ffffff;
    --button-bg: #db2121;
  }
  &.btn-blue {
    --button-text: #ffffff;
    --button-bg: #3084b9;
  }
  &.btn-green {
    --button-text: var(--bc-white);
    --button-bg: var(--bc-green-2);
  }

  &:disabled {
    opacity: 0.7;
  }

  &.inverted {
    background: var(--button-text);
    color: var(--button-bg);
    .loader {
      color: var(--button-bg);
      background: var(--button-text);
    }
  }
}
</style>
