
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import {
  defineComponent, ref, Ref, watch, computed, onMounted, nextTick, toRefs,
  onUnmounted
} from 'vue';
import DashboardLatestBlogsSkeleton from '@/views/home/components/DashboardLatestBlogsSkeleton.vue';
import ByteCookieIcon from '@/components/svg/logo/logo-circles-only.svg?inline';
import Chevron from '@/components/svg-components/Chevron.vue';
import { RawLocation } from 'vue-router';
import useI18n from '@/mixins/useI18n';
import { BlogEntry } from '@/views/blog/models/Blog';
import {
  filter, orderBy, truncate
} from 'lodash';
import axios from '@/plugins/axios';

export default defineComponent({
  components: {
    DashboardLatestBlogsSkeleton,
    ByteCookieIcon,
    Chevron
  },
  props: {
    blogType: {
      type: String,
      default: 'articles'
    },
    isLarge: {
      type: Boolean,
      default: false
    }
  },
  emits: ['no-articles'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { getFormattedDate, getFormattedTimeByAge } = useFormattedDateTime();
    const { blogType } = toRefs(props);

    const isNewsBlog = computed(() => blogType.value === 'news');
    const linkToBlog: Ref<RawLocation> = ref({ name: 'blog' });
    const allBlogs: Ref<BlogEntry[]> = ref([]);
    const featureBlog: Ref<BlogEntry | null> = ref(null);
    const featuredBlogEl: Ref<HTMLElement | null> = ref(null);
    const blogList: Ref<BlogEntry[]> = ref([]);
    const isLoading: Ref<boolean> = ref(true);

    const featureBlogLink = computed(() => (featureBlog.value ? {
      name: 'blog articles article',
      params: {
        articleId: featureBlog.value.externalId
      }
    } : {}));

    const loadBlogArticles = async (blogType: string) => {
      let entryType = 'ARTICLE';
      switch (blogType) {
        case 'news':
          entryType = 'BC_NEWS';
          break;
        default:
      }
      const { blogEntries } = await axios.get(`/v1/blog/entries?entryType=${entryType}&offset=0&limit=15`).then((res) => res.data);
      const featured = filter(blogEntries, (article) => article.position === 1);
      const regular = filter(blogEntries, (article) => article.position === 0);
      const needed = [...featured];
      if (needed.length < 4) {
        const sortedRegular = orderBy(regular, 'lastUpdated', 'desc');
        needed.push(...sortedRegular.slice(0, 4 - needed.length));
      }
      return needed.slice(0, 4);
    };

    function getArticleLink(articleId = ''): RawLocation {
      return {
        name: 'blog articles article',
        params: {
          articleId
        }
      };
    }

    watch(allBlogs, (blogs) => {
      if (blogs.length > 0) {
        const featuredArticles = blogs.filter((b) => b.position === 1);

        if (featuredArticles.length > 1) {
          const randIndex = Math.floor(Math.random() * featuredArticles.length);
          featureBlog.value = featuredArticles[randIndex];
        } else if (featuredArticles.length === 1) {
          const [singleFeatured] = featuredArticles;
          featureBlog.value = singleFeatured;
        } else {
          const randIndex = Math.floor(Math.random() * blogs.length);
          featureBlog.value = blogs[randIndex];
        }

        blogList.value = blogs.filter((b) => b.externalId !== featureBlog.value?.externalId);
      }
    });

    function setBlogList(): void {
      const featuredBlog = featuredBlogEl.value as HTMLElement;
      const height: number = featuredBlog?.clientHeight;
      if (height >= 360) {
        blogList.value = blogList.value.slice(0, 1);
      } else if (height >= 334) {
        blogList.value = blogList.value.slice(0, 2);
      }
    }

    function truncateTitle(title: string): string {
      return truncate(title, {
        length: 200,
        separator: '...'
      });
    }

    function formatDate(timestamp: string, dateformat: 'short' | 'diff' = 'diff'): string {
      if (dateformat === 'short') return getFormattedDate(timestamp);
      return getFormattedTimeByAge(timestamp);
    }

    onMounted(() => {
      loadBlogArticles(blogType.value).then((articles) => {
        allBlogs.value = articles;
        isLoading.value = false;
        nextTick(() => {
          setBlogList();
        });
        if (!allBlogs.value.length) {
          emit('no-articles');
        }
      });
      window.addEventListener('resize', setBlogList);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', setBlogList);
    });

    return {
      linkToBlog,
      allBlogs,
      isNewsBlog,
      featureBlog,
      featuredBlogEl,
      featureBlogLink,
      getArticleLink,
      blogList,
      isLoading,
      truncateTitle,
      formatDate,
      t
    };
  }
});
