
import { defineComponent, PropType } from 'vue';

import IconX from '@/components/svg/button/x.svg?inline';
import IconCheck from '@/components/svg/button/ok.svg?inline';
import IconUser from '@/components/svg/button/user.svg?inline';
import IconUsers from '@/components/svg/button/users.svg?inline';
import IconReply from '@/components/svg/button/reply.svg?inline';
import IconFlag from '@/components/svg/button/flag.svg?inline';
import IconShare from '@/components/svg/button/share.svg?inline';
import IconChat from '@/components/svg/button/chat.svg?inline';
import IconBack from '@/components/svg/button/back.svg?inline';
import IconNew from '@/components/svg/new-indicator-talk.svg?inline';
import IconNewContract from '@/components/svg/button/new-contract.svg?inline';
import IconDeleteContract from '@/components/svg/button/delete-contract.svg?inline';
import IconTimeAdd from '@/components/svg/button/time-add-s.svg?inline';
import IconTrash from '@/components/svg/button/trash.svg?inline';
import IconAbortContract from '@/components/svg/button/abort-contract.svg?inline';
import IconOkContract from '@/components/svg/button/ok-contract.svg?inline';
import IconReContract from '@/components/svg/button/re-contract.svg?inline';
import IconSendDocument from '@/components/svg/button/send-invoice.svg?inline';
import IconPreviewDocument from '@/components/svg/button/preview-invoice.svg?inline';
import IconDue from '@/components/svg/button/due.svg?inline';
import IconInvalidateInvoice from '@/components/svg/button/invalidate-invoice.svg?inline';
import IconPayedInvoice from '@/components/svg/button/payed-invoice.svg?inline';
import IconPlus from '@/components/svg/button/plus-primary.svg?inline';

const components = {
  IconShare,
  IconX,
  IconFlag,
  IconReply,
  IconCheck,
  IconUser,
  IconUsers,
  IconBack,
  IconNew,
  IconNewContract,
  IconTimeAdd,
  IconDeleteContract,
  IconChat,
  IconAbortContract,
  IconOkContract,
  IconTrash,
  IconReContract,
  IconSendDocument,
  IconPreviewDocument,
  IconDue,
  IconInvalidateInvoice,
  IconPayedInvoice,
  IconPlus,
};

type ComponentNames = keyof typeof components;
type CamelToKebab<S extends string> = S extends `${infer A}${infer B}`
  ? B extends Uncapitalize<B>
    ? `${Uncapitalize<A>}${CamelToKebab<B>}`
    : `${Uncapitalize<A>}-${CamelToKebab<B>}`
  : S;
type ExtractIconName<T> = T extends `Icon${infer Name}`
  ? CamelToKebab<Name>
  : never;
type ValidIconNames = ExtractIconName<ComponentNames>;

export default defineComponent({
  name: 'BCXButton',
  components,
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    color: {
      type: String as PropType<
        'primary' | 'secondary' | 'normal' | 'background' | 'ghost' |
        'warning' | 'ok' |
        'primary-intense' | 'secondary-intense' | 'warning-intense' | 'ok-intense'
      >,
      default: 'normal'
    },
    icon: {
      type: String as PropType<ValidIconNames>,
      default: ''
    },
    iconColor: {
      type: String as PropType<'normal' | 'secondary' | 'primary' | 'warning' | 'ok'>,
      default: 'normal'
    },
    active: Boolean,
    disabled: Boolean,
    outlined: Boolean,
    allowWrap: Boolean,
    noPadding: Boolean,
    noBackground: Boolean,
    type: {
      type: String as PropType<'default' | 'filter' | 'dialog'>,
      default: 'default'
    }
  }
});

