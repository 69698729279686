import useUser from '@/mixins/useUser';
import {
  ContractDetail, ContractStatus, ContractUpdateAddress, ContractUpdateNotes
} from '@/models/Contracts';
import { BaseUserWithRole } from '@/models/User';
import {
  acceptContract,
  addNotesToContract,
  declineContract,
  deleteContract,
  deleteContractDocument,
  getContract,
  reopenContract
} from '@/services/ContractService';
import useMessengerState from '@/state/messenger/messengerState';
import { createGlobalState, useEventBus } from '@vueuse/core';
import {
  computed, ref, watch
} from 'vue';
import router from '@/router';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import { AxiosResponse } from 'axios';
import hasObjectDiffRecursive from '@/utils/hasObjectDiff';

const useContract = createGlobalState(() => {
  const events = useEventBus('contracts');

  const { userId } = useUser('self');

  const openContractId = ref<string | null>(null);
  const contractDetail = ref<ContractDetail | null>(null);

  const handleBackendError = (response: AxiosResponse, component: string) => {
    const translatedErrors = new TranslatedBackendErrors(response);
    translatedErrors.outputGlobalErrorMessage(component);
  };

  const fetchContractDetail = async (contractId: string) => {
    try {
      openContractId.value = contractId;
      contractDetail.value = await getContract(contractId);
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectContract.vue');
    }
  };

  const isUserClient = computed(() => userId.value === (contractDetail.value?.client?.user?.userId ?? contractDetail.value?.client?.company?.rootUserId ?? ''));
  const isUserContractor = computed(() => userId.value === (contractDetail.value?.contractor?.user?.userId ?? contractDetail.value?.contractor?.company?.rootUserId ?? ''));
  const isUserCreator = computed(() => userId.value === contractDetail.value?.creator);
  const isContractStatusOpen = computed(() => contractDetail.value?.status === ContractStatus.Open);

  const contractPartnerUser = computed(() => {
    if (contractDetail.value) {
      const contractorUser = useUser((contractDetail.value.contractor.user ?? contractDetail.value.contractor) as BaseUserWithRole);
      const clientUser = useUser((contractDetail.value.client.user ?? contractDetail.value.client) as BaseUserWithRole);
      if (isUserClient.value) {
        return contractorUser;
      }
      if (isUserContractor.value) {
        return clientUser;
      }
    }
    return null;
  });

  const {
    createNewChat,
    isOpened
  } = useMessengerState();
  const openChatWithContractPartner = async () => {
    if (contractPartnerUser.value) {
      isOpened.value = true;
      try {
        if (contractPartnerUser.value.rootUserId.value) {
          await createNewChat({ userId: contractPartnerUser.value.rootUserId.value });
        } else {
          await createNewChat(contractPartnerUser.value.user.value);
        }
      } catch (e: any) {
        handleBackendError(e.response, 'MyProjectsContractsActions.vue');
      }
    }
  };

  const openContractDocumentId = ref<string | null>(null);
  const toggleOpenContractDocument = (documentId: string) => {
    if (openContractDocumentId.value === documentId) {
      openContractDocumentId.value = null;
    } else {
      openContractDocumentId.value = documentId;
    }
  };

  const deleteOpenContractDocument = async () => {
    if (!openContractDocumentId.value || !contractDetail.value) return;
    try {
      await deleteContractDocument(contractDetail.value.contractId, openContractDocumentId.value);
      contractDetail.value.documents = contractDetail.value.documents.filter((doc) => doc.id !== openContractDocumentId.value);
      openContractDocumentId.value = null;
      if (contractDetail.value.documents.length === 0) {
        contractDetail.value.deletable = true;
        contractDetail.value.readyForConclusion = false;
      }
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectsContractContract.vue');
    }
  };

  const setContractDocumentSigature = (documentId: string, signDocument: boolean) => {
    if (!contractDetail.value) return;
    const document = contractDetail.value.documents.find((doc) => doc.id === documentId);
    if (!document) return;
    if (isUserClient.value) document.signedByClient = signDocument;
    if (isUserContractor.value) document.signedByContractor = signDocument;
  };

  const submitContractNotes = async (notes: ContractUpdateNotes) => {
    if (!contractDetail.value) return;
    if (isUserContractor.value) {
      notes.contactPerson = undefined;
      notes.department = undefined;
      notes.costCenter = undefined;
    }
    try {
      if ((isUserContractor.value && notes.notes === contractDetail.value.contractor.contractNotes)
        || (notes.notes === contractDetail.value.client.contractNotes
        && notes.contactPerson === contractDetail.value.client.contactPerson
        && notes.department === contractDetail.value.client.department
        && notes.costCenter === contractDetail.value.client.costcenter)
      ) return;

      await addNotesToContract(contractDetail.value.contractId, notes);
      if (isUserContractor.value) {
        contractDetail.value.contractor.contractNotes = notes.notes ?? '';
        return;
      }
      contractDetail.value.client.contractNotes = notes.notes ?? '';
      contractDetail.value.client.contactPerson = notes.contactPerson ?? '';
      contractDetail.value.client.department = notes.department ?? '';
      contractDetail.value.client.costcenter = notes.costCenter ?? '';
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectsContractNotes.vue');
    }
  };

  // Depending on whether client or contractor, the associated data is returned in an object
  const getAddress = (contractPartnerType: 'client' | 'contractor' = 'client') => {
    const address = contractDetail.value?.[contractPartnerType]?.address;
    return {
      street: address?.street,
      houseNumber: address?.houseNumber,
      postalCode: address?.postalCode,
      city: address?.city,
      countryIsoCode: address?.countryIsoCode
    };
  };

  // Depending on whether client or contractor, the address data is stored accordingly in the contractDetail
  const setAddress = (contractPartnerType: 'client' | 'contractor' = 'client', updatedAddress: ContractUpdateAddress) => {
    const addressPath = contractDetail.value?.[contractPartnerType]?.address;
    if (addressPath) {
      addressPath.street = updatedAddress.street ?? '';
      addressPath.houseNumber = updatedAddress.houseNumber ?? '';
      addressPath.postalCode = updatedAddress.postalCode ?? '';
      addressPath.city = updatedAddress.city ?? '';
      addressPath.countryIsoCode = updatedAddress.countryIsoCode ?? '';
    }
  };

  const clientData = computed<ContractUpdateAddress>({
    get: () => getAddress('client'),
    set: (clientDataAddress: ContractUpdateAddress) => setAddress(('client'), clientDataAddress)
  });

  const contractorData = computed<ContractUpdateAddress>({
    get: () => getAddress('contractor'),
    set: (contractorDataAddress: ContractUpdateAddress) => setAddress(('contractor'), contractorDataAddress)
  });

  const referenceFieldData = computed({
    get: () => ({
      reference: contractDetail.value?.reference ?? ''
    }),
    set: (value: { reference: string }) => {
      if (contractDetail.value) {
        contractDetail.value.reference = value.reference;
      }
    }
  });

  const abortContractProcess = async () => {
    if (!contractDetail.value) return;
    try {
      await declineContract(contractDetail.value.contractId);
      if (openContractId.value) {
        fetchContractDetail(openContractId.value);
      }
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectContractConfirms.vue');
    }
  };

  const finalizeContractProcess = async () => {
    if (!contractDetail.value) return;
    try {
      await acceptContract(contractDetail.value.contractId);
      if (openContractId.value) {
        fetchContractDetail(openContractId.value);
      }
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectContractConfirms.vue');
    }
  };

  const reopenContractProcess = async () => {
    if (!contractDetail.value) return;
    try {
      await reopenContract(contractDetail.value.contractId);
      if (openContractId.value) {
        fetchContractDetail(openContractId.value);
      }
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectContract.vue');
    }
  };

  const deleteContractProcess = async () => {
    if (!contractDetail.value) return;
    try {
      await deleteContract(contractDetail.value.contractId);
      if (openContractId.value) {
        await router.push({ name: 'myProjectsContracts' });
      }
    } catch (e: any) {
      handleBackendError(e.response, 'MyProjectContractConfirms.vue');
    }
  };

  const hasChanges = ref(false);

  const newContractDetail = ref<ContractDetail | null>(null);
  const detectChanges = async () => {
    if (!openContractId.value) return;
    newContractDetail.value = await getContract(openContractId.value);
    if (!contractDetail.value || !newContractDetail.value) {
      hasChanges.value = false;
      return;
    }
    hasChanges.value = hasObjectDiffRecursive(contractDetail.value,
      newContractDetail.value,
      ['read', 'lastUpdated', 'lastChanged', 'created', 'externalFile']);
  };

  const loadChangedContract = () => {
    if (newContractDetail.value) {
      contractDetail.value = { ...newContractDetail.value };
      newContractDetail.value = null;
    }
  };

  watch(() => contractDetail.value, (value) => {
    if (contractDetail.value && value) {
      newContractDetail.value = { ...value };
      hasChanges.value = false;
    }
  }, { deep: true });

  return {
    events,
    openContractId,
    contractDetail,
    isUserClient,
    isUserContractor,
    isUserCreator,
    isContractStatusOpen,
    contractPartnerUser,
    openChatWithContractPartner,
    fetchContractDetail,
    submitContractNotes,
    openContractDocumentId,
    toggleOpenContractDocument,
    deleteOpenContractDocument,
    setContractDocumentSigature,
    clientData,
    contractorData,
    referenceFieldData,
    abortContractProcess,
    finalizeContractProcess,
    reopenContractProcess,
    deleteContractProcess,
    hasChanges,
    detectChanges,
    loadChangedContract
  };
});

export default useContract;
