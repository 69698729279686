import { render, staticRenderFns } from "./ProfileReferencesEditor.vue?vue&type=template&id=ce93a868&scoped=true"
import script from "./ProfileReferencesEditor.vue?vue&type=script&lang=ts&setup=true"
export * from "./ProfileReferencesEditor.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ProfileReferencesEditor.vue?vue&type=style&index=0&id=ce93a868&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce93a868",
  null
  
)

export default component.exports