
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { computed, defineComponent, toRefs } from 'vue';
import useI18n from '@/mixins/useI18n';
import NewProjectIndicatorIcon from '@/components/svg/new-indicator-project.svg?inline';
import NewTalkIndicatorIcon from '@/components/svg/new-indicator-talk.svg?inline';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import useQuickInfo from '@/state/quickInfo';
import { useStore } from 'vue2-helpers/vuex';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'FeedListItem',
  components: {
    BCXAvatar,
    NewProjectIndicatorIcon,
    NewTalkIndicatorIcon
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { item } = toRefs(props);
    const { t } = useI18n();
    const { getFormattedTimeByAge, getFormattedDate } = useFormattedDateTime();
    const store = useStore();

    const itemTitle = computed(() => item.value.title || item.value.metaForumThreadTitle || item.value.metatitle || item.value.metaProjectTitle);

    const itemCategory = computed(() => {
      if (['FORUM_TALK_MESSAGE_NEW', 'PROJECT_MESSAGE_NEW', 'PROJECT_CHANGED'].includes(item.value.type) && item.value.feedGroupCount > 1) {
        const categoryPlural: string = t(`subscriptions.feed-events.${item.value.type}.plural`) as string;
        return `${categoryPlural} (${item.value.feedGroupCount})`;
      }
      return t(`subscriptions.feed-events.${item.value.type}.singular`);
    });
    const itemHasNewTalkIndicator = computed(() => item.value.type === 'FORUM_TALK_NEW');
    const itemHasNewProjectIndicator = computed(() => item.value.type === 'PROJECT_NEW');

    function formatDate(timestamp: string, dateformat: 'short'|'diff' = 'diff'): string {
      if (dateformat === 'short') return getFormattedDate(timestamp);
      return getFormattedTimeByAge(timestamp);
    }

    const itemLocation = computed(() => {
      if (item.value.type.includes('FORUM_TALK')) {
        return `${t('subscriptions.talks')} - ${item.value.metaForumTitle} - ${item.value.metaForumThreadGroupTitle}`;
      } if (item.value.type.includes('PROJECT')) {
        return `${t('subscriptions.projects')} - ${t('subscriptions.all-projects')}`;
      }
      return `${item.value.metaForumThreadTitle}`;
    });

    const itemLocationLink = computed(() => {
      switch (item.value.type) {
        case 'PROJECT_NEW':
        case 'PROJECT_CHANGED':
        case 'PROJECT_MESSAGE_NEW':
          return {
            name: 'projectList',
          };
        case 'FORUM_TALK':
        case 'FORUM_TALKS':
        default:
          return {
            name: 'threads',
            params: {
              forumId: item.value.metaForumId,
              groupId: item.value.metaForumThreadGroupId,
            },
          };
      }
    });

    function showUserQuickInfoCreator(ev: Event) {
      const { showQuickInfo } = useQuickInfo();
      showQuickInfo(ev.target as HTMLElement, store.getters['userStates/getUserInfo'](item.value.creator.userId));
    }

    const itemDate = computed(() => formatDate(item.value.lastUpdate, 'diff'));

    const itemLink = computed(() => {
      switch (item.value.type) {
        case 'PROJECT_NEW':
        case 'PROJECT_CHANGED':
          return {
            name: 'projectForumThread',
            params: {
              projectId: item.value.metaProjectId || item.value.subscribedObjectIdentifier,
            },
          };
        case 'PROJECT_MESSAGE_NEW':
          return {
            name: 'projectForumThread',
            params: {
              projectId: item.value.metaProjectId || item.value.subscribedObjectIdentifier,
            },
            hash: `#reply-${item.value.metaMessageId}`,
          };
        case 'FORUM_TALK_MESSAGE_NEW':
          return {
            name: 'thread',
            params: {
              forumId: item.value.metaForumId,
              groupId: item.value.metaForumThreadGroupId,
              threadId: item.value.metaForumThreadId,
            },
            hash: `#reply-${item.value.metaMessageId}`,
          };
        case 'FORUM_TALK_NEW':
        default:
          return {
            name: 'thread',
            params: {
              forumId: item.value.metaForumId,
              groupId: item.value.metaForumThreadGroupId,
              threadId: item.value.metaForumThreadId,
            },
          };
      }
    });

    const creator = computed(() => item.value.creator);
    const { displayName: creatorName } = useUser(creator);

    return {
      itemTitle,
      itemCategory,
      itemHasNewTalkIndicator,
      itemHasNewProjectIndicator,
      showUserQuickInfoCreator,
      itemDate,
      itemLocation,
      itemLocationLink,
      itemLink,
      creatorName,
    };
  },
});
