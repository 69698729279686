import { render, staticRenderFns } from "./InvoicesOutgoing.vue?vue&type=template&id=2295bc08&scoped=true"
import script from "./InvoicesOutgoing.vue?vue&type=script&lang=ts&setup=true"
export * from "./InvoicesOutgoing.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./InvoicesOutgoing.vue?vue&type=style&index=0&id=2295bc08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2295bc08",
  null
  
)

export default component.exports