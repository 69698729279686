import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useI18n from '@/mixins/useI18n';
import { AvailabilityInterface, AvailabilityMode } from '@/models/Availability';
import { computed, unref } from 'vue';
import { MaybeRef } from '@vueuse/core';

const useAvailability = (availability: MaybeRef<AvailabilityInterface | null>) => {
  const { t } = useI18n();
  const { getFormattedDate } = useFormattedDateTime();

  const availableFrom = computed(() => {
    const { availableFrom } = unref(availability) ?? {};
    if (availableFrom) return getFormattedDate(availableFrom);
    return '';
  });

  const mode = computed<AvailabilityMode>(() => {
    const { available, availableNow, availableFulltime } = unref(availability) ?? {};
    if (!available) return AvailabilityMode.AVAILABILITY_NOT_AVAILABLE;
    if (!availableNow || !availableFulltime) return AvailabilityMode.AVAILABILITY_PARTLY_AVAILABLE;
    return AvailabilityMode.AVAILABILITY_FULLY_AVAILABLE;
  });

  const label = computed(() => `${
    mode.value === AvailabilityMode.AVAILABILITY_NOT_AVAILABLE
      ? t('availability.unavailable')
      : `${t('availability.title')}:`
  }`);

  const whenParts = (isForToolbar = false) => {
    const {
      availableFulltime, availableHoursPerWeek, available, availableNow
    } = unref(availability) ?? {};

    const parts = [];

    if (available) {
      if (isForToolbar && availableNow) parts.push(t('availability.available'));
      else if (availableFrom.value) parts.push(`${t('availability.from')} ${availableFrom.value}`);

      if (availableFulltime) parts.push(t('availability.fullTime'));
      else if (availableHoursPerWeek) parts.push(`${availableHoursPerWeek} ${t('availability.hoursPerWeekShort')}`);
      else parts.push(t('availability.partTime'));
    } else if (isForToolbar) parts.push(t('availability.unavailable'));
    return parts;
  };

  const isAvailable = computed(() => unref(availability)?.available ?? false);

  const when = computed(() => whenParts().join(' • '));
  const whenToolbar = computed(() => whenParts(true).join(' • '));

  const duration = computed<'full' | 'partly' | 'never'>(() => {
    const { availableNow, availableFulltime, available } = unref(availability) ?? {};
    if (!available) return 'never';
    if (availableNow && availableFulltime) return 'full';
    if (available) return 'partly';
    return 'never';
  });

  return {
    mode,
    label,
    when,
    whenToolbar,
    availableFrom,
    duration,
    isAvailable
  };
};

export default useAvailability;
