import { render, staticRenderFns } from "./InvoiceAddressDataDisplay.vue?vue&type=template&id=2545799a&scoped=true"
import script from "./InvoiceAddressDataDisplay.vue?vue&type=script&lang=ts&setup=true"
export * from "./InvoiceAddressDataDisplay.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./InvoiceAddressDataDisplay.vue?vue&type=style&index=0&id=2545799a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2545799a",
  null
  
)

export default component.exports